.gjs-customAm-assets {
  height: 290px;
  overflow: auto;
  clear: both;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.gjs-customAm-assets-header {
  padding: 5px;
}
.gjs-customAm-add-asset .gjs-customAm-add-field {
  width: 70%;
  float: left;
}
.gjs-customAm-add-asset button {
  width: 25%;
  float: right;
}
.gjs-customAm-preview-cont {
  position: relative;
  height: 70px;
  width: 30%;
  border-radius: 2px;
  float: left;
  overflow: hidden;
}
.gjs-customAm-preview {
  position: absolute;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.gjs-customAm-preview-bg {
  opacity: 0.5;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.gjs-customAm-dimensions {
  opacity: 0.5;
  font-size: 10px;
}
.gjs-customAm-meta {
  width: 70%;
  float: left;
  font-size: 12px;
  padding: 5px 0 0 5px;
  box-sizing: border-box;
}
.gjs-customAm-meta > div {
  margin-bottom: 5px;
}
.gjs-customAm-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
  display: none;
}
.gjs-customAm-asset {
  padding: 5px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
}
.gjs-customAm-asset:hover .gjs-customAm-close {
  display: block;
}
.gjs-customAm-assets-cont {
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  width: 45%;
  float: right;
  height: 325px;
  overflow: hidden;
}
.gjs-customAm-asset {
  width: 20%;
}

.gjs-customAm-asset:hover {
  background: #7d7d7d;
}

.gjs-customAm-preview-cont {
  width: 100%;
  height: 100px;
}

.gjs-customAm-meta {
  width: 100%;
}

.gjs-customAm-file-uploader {
  display: none;
}
.gjs-customAm-assets-cont {
  width: auto;
  float: initial;
}
.gjs-customAm-close {
  display: none !important;
}
.gjs-customAm-preview-bg.gjs-checker-bg {
  margin-top: 0;
}
