.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  width: 45%;
  height: auto;
  min-height: auto;
  min-width: 45px;
}
.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  min-height: 680px;
  max-height: 100%;
  height: 100%;
  height: calc(100% - 35px);
}

.sp-palette-container {
  border-right: none;
}
.sp-picker-container {
  border-left: none;
}
.sp-cancel {
  display: none;
}

.gjs-editor-cont {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gjs-three-bg {
  background-color: #d6aa4f;
  color: white;
}
.gjs-four-color,
.gjs-four-color-h:hover {
  color: #d6aa4f;
}

.panel__switcher {
  position: initial;
}
.panel__devices {
  position: initial;
}
.panel__actions {
  position: initial;
}
.editor-canvas {
  flex-grow: 1;
}
.panel__top {
  padding: 0;
  width: 100%;
  display: flex;
  position: initial;
  justify-content: center;
  justify-content: space-between;
  background-color: #444;
  height: 35px;
}

.panel__basic-actions {
  position: initial;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  overflow-y: auto;
  background-color: #444;
}

.editor-row ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.editor-row ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.editor-row ::-webkit-scrollbar {
  width: 8px;
}

.gjs-am-file-uploader > form {
  background-color: inherit !important;
  padding: 0 !important;
}

.gjs-editor-cont::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.8) !important;
}

.gjs-field-colorp {
  z-index: 222;
  position: unset;
  margin-left: calc(100% - 22px);
  border-left: none;
}

.gjs-checker-bg {
  margin-top: -26px;
}

.gjs-am-preview-bg.gjs-checker-bg {
  margin-top: 0;
}

.gjs-field-color-picker {
  z-index: 11;
}

/* needed to place editor modals when used inside tabs */
.gjs-mdl-container {
  left: unset;
  right: unset;
  width: 100%;
  padding: 0% 25% 0% 10%;
}

.icons-flex {
  background-size: 50% 50% !important;
  height: 24px;
  width: 30px;
  opacity: 0.9;
  margin: auto;
}
.icon-dir-row {
  background: url('../images/grapesjs-icons/flex-dir-row.png') no-repeat center;
}
.icon-dir-row-rev {
  background: url('../images/grapesjs-icons/flex-dir-row-rev.png') no-repeat center;
}
.icon-dir-col {
  background: url('../images/grapesjs-icons/flex-dir-col.png') no-repeat center;
}
.icon-dir-col-rev {
  background: url('../images/grapesjs-icons/flex-dir-col-rev.png') no-repeat center;
}
.icon-just-start {
  background: url('../images/grapesjs-icons/flex-just-start.png') no-repeat center;
}
.icon-just-end {
  background: url('../images/grapesjs-icons/flex-just-end.png') no-repeat center;
}
.icon-just-sp-bet {
  background: url('../images/grapesjs-icons/flex-just-sp-bet.png') no-repeat center;
}
.icon-just-sp-ar {
  background: url('../images/grapesjs-icons/flex-just-sp-ar.png') no-repeat center;
}
.icon-just-sp-cent {
  background: url('../images/grapesjs-icons/flex-just-sp-cent.png') no-repeat center;
}
.icon-al-start {
  background: url('../images/grapesjs-icons/flex-al-start.png') no-repeat center;
}
.icon-al-end {
  background: url('../images/grapesjs-icons/flex-al-end.png') no-repeat center;
}
.icon-al-str {
  background: url('../images/grapesjs-icons/flex-al-str.png') no-repeat center;
}
.icon-al-center {
  background: url('../images/grapesjs-icons/flex-al-center.png') no-repeat center;
}
